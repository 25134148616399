<template>
  <div>
    <v-app>
      <v-container>
        <div class="page-card">
          <v-form v-model="isFormValid">
            <v-row>
              <v-col md="6">
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.centerName"
                        label="Merkez Adı (*)"
                        filled
                        dense
                        :rules="rules.name"
                      ></v-text-field></v-col
                  ></v-row>
                </div>
                <!-- <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        :rules="rules.name"
                        label="Bölümü Seçiniz... (*)"
                        v-model="selected.department"
                        filled
                        dense
                        :items="specialtyDepartmentitems"
                        item-text="text"
                        item-value="value"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div> -->
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        :rules="rules.name"
                        :items="titleitems"
                        label=" Merkez Tipi Seçiniz.. (*)"
                        v-model="selected.centerType"
                        filled
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="Ülke Seçiniz"
                        v-model="selected.country"
                        filled
                        :items="countryitems"
                        item-text="countryName"
                        item-value="countryId"
                        @change="getCity()"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="İlçe Seçiniz"
                        v-model="selected.district"
                        filled
                        :items="districtitems"
                        item-text="townName"
                        item-value="townId"
                        no-data-text="Lütfen Önce İl Seçiniz"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col md="6">
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.landPhone"
                        label="Sabit Telefon (*)"
                        filled
                        dense
                        maxlength="10"
                        :rules="rules.phone"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.email"
                        label="E-posta"
                        filled
                        dense
                        :rules="rules.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.webAddress"
                        label="Web adresi"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        auto-select-first
                        clearable
                        label="İl Seçiniz"
                        v-model="selected.city"
                        filled
                        :items="cityitems"
                        item-text="cityName"
                        item-value="cityId"
                        @change="getDistrictItems()"
                        no-data-text="Lütfen Önce Ülke Seçiniz"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>

                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-text-field
                        required
                        v-model="selected.postCode"
                        label="Posta Kodu"
                        filled
                        dense
                        :rules="rules.postcode"
                        maxlength="5"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <div class="form-group">
                  <v-row>
                    <v-col>
                      <v-textarea
                        required
                        v-model="selected.address"
                        label="Açık Adres"
                        filled
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col md="2 ml-auto">
              <button
                type="button"
                class="btn btn-primary"
                @click="send"
                :disabled="!isFormValid"
                id="btnSaveCenter"
              >
                Kaydet
              </button>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<style lang="scss">
//@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>

<script>
//import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { REMOVE_SELECTEDPAGEITEMNAME } from "@/core/services/store/selectedpageitem.module";
//import KTUtil from "@/assets/js/components/util";
//import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
//import { REGISTER } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "centers",
  data() {
    return {
      rules: {
        name: [(val) => !!val || "Bu alan doldurulmalı"],
        //counter: [v => (v.length >= 10) || 'Telefon Numaranızı 10 Haneli Olarak Giriniz'],
        phone: [
          (v) => {
            var regex = /[0-9]{10}/;
            return (
              regex.test(v) || v.length == 0 || "Geçersiz Telefon Numarası"
            );
          },
        ],
        postcode: [
          (v) => {
            var regex = /[0-9]{5}/;
            return regex.test(v) || v.length == 0 || "Geçersiz Posta Kodu";
          },
        ],
        email: [
          (v) => {
            const pattern = /^([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;
            return (
              pattern.test(v) ||
              v.length == 0 ||
              "Lütfen Geçerli E-posta Adresi Giriniz."
            );
          },
        ],
      },
      isFormValid: false,
      selected: {
        centerName: "",
        department: "",
        centerType: "",
        landPhone: "",
        email: "",
        webAddress: "",
        country: "",
        city: "",
        district: "",
        postCode: "",
        address: "",
      },
      titleitems: [
        { text: "Özel Hastane", value: "Özel Hastane" },
        {
          text: "Eğitim Araştırma Hastanesi",
          value: "Eğitim Araştırma Hastanesi",
        },
        { text: "Üniversite Hastanesi", value: "Üniversite Hastanesi" },
      ],
      specialtyDepartmentitems: [
        { text: "Kardiyoloji", value: "Kardiyoloji" },
        { text: "Kardiyoloji ABD", value: "Kardiyoloji ABD" },
        { text: "Nefroloji", value: "Nefroloji" },
        { text: "Diğer", value: "Diğer" },
      ],
      countryitems: [],
      cityitems: [],
      districtitems: [],
    };
  },
  destroyed() {
    this.$store.dispatch(REMOVE_SELECTEDPAGEITEMNAME);
  },
  mounted() {
    let selectedPage = this.$store.state.selectedpageitem.selectedPageItemName;
    if (selectedPage === "") {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Merkez Ekle", route: "/centers" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: selectedPage, route: "/centerlist" },
        { title: "Merkez Ekle" },
      ]);
    }

    this.getCountryItems();
  },
  methods: {
    send() {
      document.getElementById('btnSaveCenter').disabled = true;
      console.log("datalar burada", this.selected);
      ApiService.post("/center", this.selected)
        .then(({ data }) => {
          console.log("yeni merkezler içerde", data);
          Swal.fire({
            icon: "success",
            title: "Kayıt işlemi Başarılı",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((err) => {
          document.getElementById('btnSaveCenter').disabled = false;
          console.log("error", err);
          Swal.fire({
            title: "",
            text: err.response.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    getCountryItems() {
      ApiService.get("countries")
        .then(({ data }) => {
          this.countryitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getCity() {
    
      this.selected.city = "";
      this.selected.district = "";
      if (this.selected.country == null) {
        this.cityitems = [];
        this.districtitems = [];
      }
      ApiService.get("cities", this.selected.country)
        .then(({ data }) => {
          this.cityitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getDistrictItems() {
      this.selected.district = "";
      if (this.selected.city == null) {
        this.districtitems = [];
      }
      ApiService.get("towns", this.selected.city)
        .then(({ data }) => {
          this.districtitems = data;
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        console.log("query", this.$route.query.tab);
        return this.$route.query.tab;
      },
    },
  },
};
</script>
